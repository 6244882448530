<template>
    <b-row>
      <b-col sm="12">
        <iq-card body-class=" profile-page p-0">
          <template v-slot:body>
            <div class="profile-header">
              <div class="profile-info p-2">
                <b-row>
                  <b-col md="6" sm="12">
                    <div class="user-detail pl-5">
                      <div class="d-flex flex-wrap align-items-center">
                        <div class="profile-img pr-4">
                          <b-img v-bind:src="user.profile" ref="file" alt="profile-img" fluid class="avatar-80 cursor-pointer" />
                          <input type="file" id="file" class="d-none position-absolute" accept="image/*" style="opacity: 0;" />
                        </div>
                        <div class="profile-detail d-flex align-items-center">
                          <h4>{{user.name}}</h4>
                          <h5 class="m-0 pl-3 text-secondary"> {{user.phone}} </h5>
                        </div>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="6" sm="12" class="d-flex align-items-center justify-content-center">
                    <tab-nav :pills="true" id="pills-tab" class="profile-feed-items d-flex justify-content-between">
                      <tab-nav-items :active="true" id="pills-feed-tab" href="#profile-package" ariaControls="pills-home" role="tab" :ariaSelected="true" title="Membership" />
                      <tab-nav-items :active="false" id="pills-friend-tab" href="#profile-friends" ariaControls="pills-contact" role="tab" :ariaSelected="false" title="Financial" />
                      <tab-nav-items :active="false" id="pills-profile-tab" ariaControls="pills-contact" role="tab" :ariaSelected="false" title="trainning" />
                      <tab-nav-items :active="false" id="pills-activities-tab" href="#profile-activity" ariaControls="pills-contact" role="tab" :ariaSelected="false" title="activities" />
                    </tab-nav>
                  </b-col>
                </b-row>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col sm="12">
        <b-row>
          <b-col lg="4" class="profile-right">
            <user-info :user= "user" />
            <invitations></invitations>
          </b-col>
          <b-col lg="8" class="profile-center">
            <tab-content id="pills-tabContent-2">
              <tab-content-item :active="true" id="profile-package" aria-labelled-by="pills-feed-tab">
                <div class="iq-card p-1">
                  <b-tabs content-class="mt-1 pb-0" align="left">
                    <b-tab title="Packages" active @click="setgetMemberPackagesOrMemberServices('package')">
                    </b-tab>
                    <b-tab title="Services" @click="setgetMemberPackagesOrMemberServices('service')">
                    </b-tab>
                  </b-tabs>
                </div>
                <!--details-->
                <packageOrServiceDetails :details="getMemberPackagesOrMemberServices" :loading="packageOrServiceDetailsLoading"/>
              </tab-content-item>
              <tab-content-item :active="false" id="profile-activity" aria-labelled-by="pills-activity-tab">
                <div class="iq-card p-1">
                  <b-tabs content-class="mt-1 pb-0" align="left">
                    <b-tab title="Check In" active @click="getVisitReport('Check In')">
                    </b-tab>
                  </b-tabs>
                </div>
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">{{activityTitle}} Activity</h4>
                  </template>
                  <template v-slot:body>
                    <div v-if="AllVisits.length > 0">
                    <ul class="iq-timeline" id="visiteList">
                      <li v-for="(item,index) in AllVisits" :key="index">
                        <div class="timeline-dots" :class="'border-primary'"></div>
                        <h6 class="float-left mb-1">Check In</h6>
                        <small class="float-right mt-1 font-weight-bold text-success" v-html="item.check_in"></small>
                        <div class="d-inline-block w-100">
                          <h6 class="float-left mb-1 text-secondary">Check Out</h6>
                          <small class="float-right mt-1 font-weight-bold text-danger" v-html="item.check_in"></small>
                        </div>
                      </li>
                    </ul>
                    <b-pagination v-model="visiteCurrentPage"
                                  class="mt-3"
                                  :total-rows="visitetotal"
                                  align="right"
                                  :per-page="visiteper_page"
                                  aria-controls="visiteList"
                                  @input="changeContentActivities"
                    ></b-pagination>
                    </div>
                    <div v-else>
                      <iq-card body-class="p-0" class="rounded text-center">
                        <img :src="require('../../assets/images/error/search.png')" class="avatar-200" />
                        <p class="font-size-18 pb-2">Oops!... no data found</p>
                      </iq-card>
                    </div>
                  </template>
                </iq-card>
              </tab-content-item>
              <tab-content-item :active="false" id="profile-friends" aria-labelled-by="pills-friend-tab">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Financial</h4>
                  </template>
                  <template v-slot:body>
                    <div v-if="financialList.length > 0">
                      <ul class="iq-timeline" id="financialList">
                      <li v-for="(item,index) in financialList" :key="index">
                        <div class="timeline-dots" :class="'border-primary'"></div>
                        <h6 class="float-left mb-1">Pay <span class="text-success font-weight-bold">{{parseFloat(item.pay)}} EGP</span> in {{item.member_package.package_title}}</h6>
                        <small class="float-right mt-1 font-weight-bold text-success"> {{item.created_at.substring(0,10)}}</small>
                        <div class="d-inline-block w-100">
                          <h6 class="float-left mb-1">Remaining <span class="text-danger font-weight-bold">{{parseFloat(item.remaining)}} EGY</span> <span v-if="false" class="d-inline-block ml-5">Receptionist : {{item.user.name}}</span></h6>
                        </div>
                      </li>
                    </ul>
                      <b-pagination v-model="financialCurrentPage"
                                  class="mt-3"
                                  :total-rows="financialtotal"
                                  align="right"
                                  :per-page="financialper_page"
                                  aria-controls="financialList"
                                  @input="changeContentFinancial"
                    ></b-pagination>
                    </div>
                    <div v-else>
                      <iq-card body-class="p-0" class="rounded text-center">
                        <img :src="require('../../assets/images/error/search.png')" class="avatar-200" />
                        <p class="font-size-18 pb-2">Oops!... no data found</p>
                      </iq-card>
                    </div>
                  </template>
                </iq-card>
              </tab-content-item>
              <tab-content-item :active="false" id="profile-profile" aria-labelled-by="pills-profile-tab">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Profile</h4>
                  </template>
                  <template v-slot:body>
                    <div class="user-detail text-center">
                      <div class="user-profile">
                        <b-img :src="require('../../assets/images/user/user-11.png')" alt="profile-img" fluid class="avatar-130" />
                      </div>
                      <div class="profile-detail mt-3">
                        <h3 class="d-inline-block">Nik Jone</h3>
                        <p class="d-inline-block pl-3"> - Web designer</p>
                        <p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 500s</p>
                      </div>
                    </div>
                  </template>
                </iq-card>
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">About User</h4>
                  </template>
                  <template v-slot:body>
                    <div class="user-bio">
                      <p>Tart I love sugar plum I love oat cake. Sweet roll caramels I love jujubes.Topping cake wafer.</p>
                    </div>
                    <div v-for="(item,index) in userBio" :key="index" class="mt-2">
                      <h6>{{ item.title }}:</h6>
                      <p v-html="item.description"></p>
                    </div>
                  </template>
                </iq-card>
              </tab-content-item>
            </tab-content>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
</template>
<script>
import { core } from '../../config/pluginInit'
import info from '../../components/members/profile/info'
import packageOrServiceDetails from '../../components/members/profile/packageOrServiceDetails'
import { mapGetters } from 'vuex'
const invitations = () => import('../../components/members/profile/invitations')
export default {
  name: 'profile',
  mounted () {
    core.index()
  },
  data () {
    return {
      user: {
        profile: require('../../assets/images/user/user-11.png'),
        name: '',
        phone: '',
        membership_id: '',
        email: '',
        type: '',
        birthday: '',
        national_id: ''
      },
      activityTitle: '',
      visiteCurrentPage: 1,
      visiteper_page: 10,
      visitetotal: 6,
      visiteLoading: true,
      galary: [
        { image: require('../../assets/images/page-img/g1.jpg'), href: 'javascript:void(0);' },
        { image: require('../../assets/images/page-img/g2.jpg'), href: 'javascript:void(0);' },
        { image: require('../../assets/images/page-img/g3.jpg'), href: 'javascript:void(0);' },
        { image: require('../../assets/images/page-img/g4.jpg'), href: 'javascript:void(0);' },
        { image: require('../../assets/images/page-img/g5.jpg'), href: 'javascript:void(0);' },
        { image: require('../../assets/images/page-img/g6.jpg'), href: 'javascript:void(0);' },
        { image: require('../../assets/images/page-img/g7.jpg'), href: 'javascript:void(0);' },
        { image: require('../../assets/images/page-img/g8.jpg'), href: 'javascript:void(0);' },
        { image: require('../../assets/images/page-img/g9.jpg'), href: 'javascript:void(0);' }
      ],
      AllVisits: [],
      timelineItems: [
        {
          color: 'primary',
          title: 'Client Login',
          right: '24 November 2019',
          description: 'Bonbon macaroon jelly beans gummi bears jelly lollipop apple',
          child: {
            type: 'img',
            items: [
            ]
          }
        },
        {
          color: 'success',
          title: 'Scheduled Maintenance',
          right: '23 November 2019',
          description: 'Bonbon macaroon jelly beans gummi bears jelly lollipop apple',
          child: {
            type: 'img',
            items: [
            ]
          }
        },
        {
          color: 'danger',
          title: 'Dev Meetup',
          right: '20 November 2019',
          description: 'Bonbon macaroon jelly <b-link href="">beans gummi</b-link> bears jelly lollipop apple',
          child: {
            type: 'img',
            items: [
              require('../../assets/images/user/user-05.jpg'),
              require('../../assets/images/user/user-06.jpg'),
              require('../../assets/images/user/user-07.jpg'),
              require('../../assets/images/user/user-08.jpg'),
              require('../../assets/images/user/user-09.jpg'),
              require('../../assets/images/user/user10.jpg')
            ]
          }
        },
        {
          color: 'primary',
          title: 'Client Call',
          right: '19 November 2019',
          description: 'Bonbon macaroon jelly beans gummi bears jelly lollipop apple',
          child: {
            type: 'img',
            items: [
            ]
          }
        },
        {
          color: 'warning',
          title: 'Mega Event',
          right: '15 November 2019',
          description: 'Bonbon macaroon jelly beans gummi bears jelly lollipop apple',
          child: {
            type: 'img',
            items: [
            ]
          }
        }
      ],
      friends: [
        { name: 'Paul Molive', role: 'Web Designer', image: require('../../assets/images/user/user-01.jpg') },
        { name: 'Paul Molive', role: 'trainee', image: require('../../assets/images/user/user-01.jpg') },
        { name: 'Anna Mull', role: 'Web Developer', image: require('../../assets/images/user/user-02.jpg') },
        { name: 'Paige Turner', role: 'trainee', image: require('../../assets/images/user/user-03.jpg') },
        { name: 'Barb Ackue', role: 'Web Designer', image: require('../../assets/images/user/user-04.jpg') },
        { name: 'Greta Life', role: 'Tester', image: require('../../assets/images/user/user-05.jpg') },
        { name: 'Ira Membrit', role: 'Android Developer', image: require('../../assets/images/user/user-06.jpg') },
        { name: 'Pete Sariya', role: 'Web Designer', image: require('../../assets/images/user/user-07.jpg') }
      ],
      userBio: [
        { title: 'Joined', description: 'November 15, 2012' },
        { title: 'Lives', description: 'United States of America' },
        { title: 'Email', description: '<a href="mailto:nikjone@gmail.com"> nikjone@gmail.com</a>' },
        { title: 'Url', description: '<a href="https://getbootstrap.com/docs/4.0/getting-started/introduction/" target="_blank"> www.bootstrap.com </a>' },
        { title: 'Contact', description: '<a href="tel:001 4544 565 456">(001) 4544 565 456</a>' }
      ],
      story: [
        { title: 'Web Design', time: '1 hour ago', image: require('../../assets/images/page-img/s1.jpg'), class: 'mb-4', isActive: 'active' },
        { title: 'App Design', time: '4 hour ago', image: require('../../assets/images/page-img/s2.jpg'), class: 'mb-4', isActive: '' },
        { title: 'Abstract Design', time: '9 hour ago', image: require('../../assets/images/page-img/s3.jpg'), class: '', isActive: '' }
      ],
      /* suggestions: [
        { name: 'Paul Molive', mutual_friend: '4 mutual friends', image: require('../../assets/images/user/user-01.jpg') },
        { name: 'Paul Molive', mutual_friend: '4 mutual friends', image: require('../../assets/images/user/user-01.jpg') },
        { name: 'Anna Mull', mutual_friend: '6 mutual friends', image: require('../../assets/images/user/user-02.jpg') },
        { name: 'Paige Turner', mutual_friend: '8 mutual friends', image: require('../../assets/images/user/user-03.jpg') },
        { name: 'Barb Ackue', mutual_friend: '1 mutual friends', image: require('../../assets/images/user/user-04.jpg') },
        { name: 'Greta Life', mutual_friend: '3 mutual friends', image: require('../../assets/images/user/user-05.jpg') },
        { name: 'Ira Membrit', mutual_friend: '12 mutual friends', image: require('../../assets/images/user/user-06.jpg') },
        { name: 'Pete Sariya', mutual_friend: '2 mutual friends', image: require('../../assets/images/user/user-07.jpg') }
      ], */
      news: [
        { description: 'there is a meetup in your city on friday at 19:00.<a href="#">see details</a>' },
        { description: '20% off coupon on selected items at pharmaprix' }
      ],
      twitterFeed: [
        {
          image: require('../../assets/images/page-img/42.jpg'),
          name: 'UI/Ux Designer',
          description: 'Creativity Design'
        },
        {
          image: require('../../assets/images/page-img/43.jpg'),
          name: 'Marketing',
          description: 'Accounting'
        },
        {
          image: require('../../assets/images/page-img/44.jpg'),
          name: 'Web Developer',
          description: 'uniq Concept'
        },
        {
          image: require('../../assets/images/page-img/45.jpg'),
          name: 'Latest News',
          description: 'Line information'
        }
      ],
      recentPages: [
        {
          image: require('../../assets/images/page-img/46.jpg'),
          name: 'Food town'
        },
        {
          image: require('../../assets/images/page-img/47.jpg'),
          name: 'Touro Univercity'
        },
        {
          image: require('../../assets/images/page-img/48.jpg'),
          name: 'Moviehouse & Eatery'
        },
        {
          image: require('../../assets/images/page-img/49.jpg'),
          name: 'Coffee + Crisp'
        }
      ],
      packageOrServiceDetailsLoading: true,
      financialList: [],
      financialCurrentPage: 1,
      financialper_page: 10,
      financialtotal: 6,
      financialLoading: true
    }
  },
  computed: {
    ...mapGetters(['getMemberPackagesOrMemberServices'])
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    packageOrServiceDetails,
    invitations,
    userInfo: info
  },
  methods: {
    resetUser () {
      this.user = {
        profile: require('../../assets/images/user/user-11.png'),
        name: '',
        phone: '',
        membership_id: '',
        email: '',
        type: '',
        birthday: '',
        national_id: ''
      }
    },
    getInfoAboutMember () {
      this.resetUser()
      this.$store.dispatch('getMembership', this.$route.params.id).then(res => {
        this.user.name = res.data.data.name
        this.user.phone = res.data.data.phone
        this.user.membership_id = res.data.data.membership_id
        this.user.type = res.data.data.type
        this.user.email = res.data.data.email
        this.user.national_id = res.data.data.national_id
        this.user.birthday = res.data.data.birthday
      })
    },
    setgetMemberPackagesOrMemberServices (type = 'package') {
      this.packageOrServiceDetailsLoading = true
      this.$store.dispatch('getMemberPackagesOrMemberServices', { id: this.$route.params.id, type: type }).then(() => {
        this.packageOrServiceDetailsLoading = false
      })
    },
    getVisitReport () {
      this.activityTitle = 'Check In'
      this.inviteLoading = true
      this.$store.dispatch('getAllVisits', {
        pageNumber: this.visiteCurrentPage,
        memberId: this.$route.params.id
      }).then(res => {
        this.AllVisits = res.data.data
        this.visiteCurrentPage = res.data.meta.current_page
        this.visiteper_page = res.data.meta.per_page
        this.visitetotal = res.data.meta.total
        this.visiteLoading = false
      })
    },
    getAllPayments () {
      this.financialLoading = true
      this.$store.dispatch('getAllPayments', {
        pageNumber: this.financialCurrentPage,
        memberId: this.$route.params.id
      }).then(res => {
        console.log('res', res)
        this.financialList = res.data.data
        this.financialCurrentPage = res.data.meta.current_page
        this.financialper_page = res.data.meta.per_page
        this.financialtotal = res.data.meta.total
        this.financialLoading = false
      })
    },
    changeContentActivities () {
      this.getVisitReport()
    },
    changeContentFinancial () {
      this.getAllPayments()
    }
  },
  created () {
    this.getInfoAboutMember()
    this.setgetMemberPackagesOrMemberServices()
    this.getVisitReport()
    this.getAllPayments()
  }
}
</script>
<style scoped>
.profile-img{
  margin-top: 0 !important;
}
</style>

<template>
  <iq-card>
    <template v-slot:headerTitle>
      <h4 class="card-title">Info</h4>
    </template>
    <template v-slot:body>
      <div class="about-info m-0 p-0" v-if="user.membership_id">
        <b-row class="mb-2">
          <b-col cols="3">ID: </b-col>
          <b-col cols="9">{{user.membership_id}}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="3">Email: </b-col>
          <b-col cols="9">{{user.email}}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="3">Phone:</b-col>
          <b-col cols="9">{{user.phone}}</b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col cols="3">Birthday:</b-col>
          <b-col cols="9">{{user.birthday}}</b-col>
        </b-row>
        <b-row>
          <b-col cols="3">National:</b-col>
          <b-col cols="9">{{user.national_id}}</b-col>
        </b-row>
      </div>
      <div class="about-info m-0 p-0" v-else>
        <div class="text-center">
          <b-spinner small type="grow"></b-spinner>
          Loading...
        </div>
      </div>
    </template>
  </iq-card>
</template>

<script>
export default {
  name: 'info',
  props: {
    user: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <b-container fluid>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-form-group
                        class="col-md-12"
                        label="Select Services:"
                        label-for="Services"
          >
            <div>
              <select class="form-control" id="category" required  v-model="details.packageId">
                <option selected disabled>choose package</option>
                <option v-for="(i , key) in availablePackages" :key="key" :value="i.id">
                  {{ i.title }}
                </option>
              </select>
            </div>
          </b-form-group>
          <b-form-group class="col-md-6" label="Fees:" label-for="fees">
            <ValidationProvider name="fees" ref="fees" rules='required' v-slot="{ errors }" >
              <b-form-input type="text" v-model="details.fees" placeholder="Ex: 200" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group class="col-md-6" label="Payment Type:" label-for="period">
            <div class="periodNumbers">
                        <span :class="[{ 'shadow selectedNumber' : isSelected('cash')}]" @click="details.payment_method = 'cash'">
                          Cash
                        </span>
              <span :class="[{ 'shadow selectedNumber' : isSelected('visa')}]" @click="details.payment_method = 'visa'">
                          Visa
                        </span>
            </div>
          </b-form-group>
        </b-row>
        <div class="d-flex align-items-center justify-content-end">
          <b-button variant="primary" type="submit" >upgrade</b-button>
        </div>
      </form>
    </ValidationObserver>
  </b-container>
</template>

<script>
import { core } from '../../../../config/pluginInit'

export default {
  name: 'upgrade',
  props: {
    availablePackages: {
      type: Array
    }
  },
  data () {
    return {
      details: {
        fees: '',
        packageId: '',
        payment_method: 'cash'
      }
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    isSelected (i) {
      // eslint-disable-next-line eqeqeq
      return i == this.details.payment_method
    },
    onSubmit () {
      this.$emit('upgrade', { details: this.details })
    }
  }
}
</script>

<style scoped>

</style>
